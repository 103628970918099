import '../scss/foundation.scss';
import '../scss/main.scss';

// Either import everything
//import 'foundation-sites;


// Or import individual modules
/**/
import 'foundation-sites/dist/js/plugins/foundation.core';
import 'foundation-sites/dist/js/plugins/foundation.dropdownMenu.min.js';
import 'foundation-sites/dist/js/plugins/foundation.util.keyboard.min.js';
import 'foundation-sites/dist/js/plugins/foundation.util.box.min.js';
import 'foundation-sites/dist/js/plugins/foundation.util.nest.min.js';
import 'foundation-sites/dist/js/plugins/foundation.util.mediaQuery';
import 'foundation-sites/dist/js/plugins/foundation.util.imageLoader';
import 'foundation-sites/dist/js/plugins/foundation.util.motion';
import 'foundation-sites/dist/js/plugins/foundation.util.triggers';
import 'foundation-sites/dist/js/plugins/foundation.util.touch';
import 'foundation-sites/dist/js/plugins/foundation.equalizer';
import 'foundation-sites/dist/js/plugins/foundation.reveal';
import 'foundation-sites/dist/js/plugins/foundation.tabs';


import jQueryBridget from 'jquery-bridget';
import Masonry from 'masonry-layout';
import Accordions from './modules/Accordions';
import Glide from '@glidejs/glide';

document.addEventListener("DOMContentLoaded", function () {
	$(document).foundation();

	// mobile hamburger menu
	$('.hamburger').on('click', function (e) {
		$('.hamburger').toggleClass('is-active');
		$('.nav-menu-container').toggleClass('is-active');
		$('.nav-main').toggleClass('menu-active');
		$('.nav-bg').toggleClass('is-active');
		e.preventDefault();
	});

	// hash scroll
	$('a[href^="#"]').on('click',function (e) {
		var target = this.hash;
		if(target != "#maincontent"){
			e.preventDefault();
			var $target = $(target);
			$('html, body').stop().animate({
				'scrollTop': $target.offset().top - 100
			}, 500, 'swing');
			$target.focus();
		}
	});	

	// load more
	$('#loadmore').on('click', function (e) {
		e.preventDefault();
		$('.role-item:hidden').slice(0, 2).slideDown();
		if ($('.role-item:hidden').length == 0) {
			$('#loadmore').addClass('hide');
		}
	});

	// video modal open
	$('.modal-link').on('click', function () {
		//$('#video-modal').foundation('open');
		$('#'+$(this).attr('data-video-id')).foundation('open');
		$('.embed-player').attr("src", $(this).attr('data-video-link'));
		$('.embed-player').attr("title", $(this).attr('data-video-title'));
		$('.video-transcript .btn').attr("href", $(this).attr('data-video-transcript'));
	});

	// reveal video close and stop
	$('.reveal-overlay').on('click', function (e) {
		$('.reveal-video iframe').attr('src', '');
	});
	$('.reveal-video .close-button').on('click', function (e) {
		$('.reveal-video iframe').attr('src', '');
	});
	$('.reveal-video .video-transcript .btn-primary').on('click', function (e) {
		var tempLink = $('.reveal-video iframe').attr('src');
		var tempTitle = $('.reveal-video iframe').attr('title');
		$('.reveal-video iframe').attr('src', '');
		setTimeout(function(){ 
			$('.reveal-video iframe').attr('src', tempLink);
			$('.reveal-video iframe').attr('title', tempTitle);
		}, 500);
	});

	// escape key
	$(document).on('keyup', function (evt) {
		if (evt.keyCode === 9) {
			$('.menu > li .primary-link').off().on('focusin', function(e) {
				$('.submenu').removeClass('js-dropdown-active');
				$('.is-dropdown-submenu-parent').removeClass('is-active');
			});	
		}

		if (evt.keyCode == 27) {
			$('.submenu').removeClass('js-dropdown-active');
			$('.reveal-video iframe').attr('src', '');
		}
	});

	// role modal open
	$('.role-modal-link').on('click', function () {
		$('#role-modal').foundation('open');
		$('.role-modal-content').html($(this).attr('data-role-content'));
		$('.role-modal-title').text($(this).attr('data-role-title'));
	});

	// policy modal open
	$('.policy-modal-link').on('click', function () {
		$('#policy-modal').foundation('open');
		$('.btn-accept').attr("href", $(this).attr('data-apply-link'));
		$('.policy-modal-title').text($(this).attr('data-apply-title'));
	});

	// Close policy modal on apply
	$('.btn-accept').on('click', function () {
		$('#policy-modal').foundation('close');
	});

	// accordions
	const accordion = new Accordions();
	accordion.init();

	// masonry grid
	jQueryBridget('masonry', Masonry, $);
	$('.grid-masonry').masonry({
		itemSelector: '.grid-item',
		percentPosition: true,
		gutter: '.gutter-sizer',
		fitWidth: true
	});

	// tabs
	$('.tabs-title a').on('click', function() {		
		var tabId = $(this).attr('data-tabs-target');
		if($(this).first().parents().eq(2).hasClass('.tab-container')){
			var tabContainer = $(this).first().parents().eq(2);
		}else {
			var tabContainer = $(this).first().parents().eq(3);
		}				
		tabContainer.find('.tab-accordion-title').removeClass('is-active');
		tabContainer.find(`.tab-accordion-title[data-tab-id='${tabId}']`).addClass('is-active');
	});	
	// tabs mobile
	$('.tab-accordion-title').on('click', function() {
		var currId = $(this).attr('data-tab-id');
		if($(this).first().parents().eq(4).hasClass('.tab-container')){
			var tabMobileContainer = $(this).first().parents().eq(4);
		}else {
			var tabMobileContainer = $(this).first().parents().eq(5);
		}	
		$(this).parent('.tabs-panel').removeClass('is-active').attr("aria-hidden","false");
		$(this).parent('.tabs-panel').siblings().removeClass('is-active').attr("aria-hidden","false");		
		tabMobileContainer.find('.tab-accordion-title').removeClass('is-active');
		$('#'+currId).addClass('is-active');
		$(this).addClass('is-active');
		tabMobileContainer.find('.tabs-title').removeClass('is-active');
		tabMobileContainer.find('.tabs-title').find(`[data-tabs-target='${currId}']`).parent('.tabs-title').addClass('is-active');
	});
	
	// tabs mobile AA
	function checkTabs(){
		if ( $(window).width() < 1023 ){
			$('.tabs-panel').attr("aria-hidden","false");
		}
	}
	checkTabs();
	$(window).resize(checkTabs);
	
	// hero video
	$('.hero-banner-pause').on('click', function() {
		var currVid = $(this).attr('data-video-id');
		if ($('#'+currVid)[0].paused) {
			$('#'+currVid)[0].play();
			$(this)[0].innerHTML = '<span class="video-icon"></span><span class="video-text">Pause</span>';
			$(this).toggleClass('video-play video-pause');
		} else {
			$('#'+currVid)[0].pause();
			$(this)[0].innerHTML = '<span class="video-icon"></span><span class="video-text">Play</span>';
			$(this).toggleClass('video-pause video-play');
		}
	});

	// glide slider
	const COMPONENT_NAME = "data-glide";
	const COMPONENT_SELECTOR = `[${COMPONENT_NAME}]`;

	const components = document.querySelectorAll(COMPONENT_SELECTOR);

	for (let i = 0; i < components.length; i++) {
		const options = JSON.parse(
			components[i].getAttribute(COMPONENT_NAME) || "{}"
		);

		let glide = new Glide(
			components[i],
			options
		);

    components[i].addEventListener('keyup', (e) => {
			if (e.keyCode == 39){
				glide.go('>');
			} 
			if (e.keyCode == 37){
				glide.go('<');
			} 
		});		

		glide.mount();
	}

});